<!--
 * @Description:计费规则详情 billRuleDetail
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-05-11 19:38:45
 * @LastEditors: zhoucheng
-->
<template>
  <div class='billRuleDetail'>
    <van-nav-bar title="收费规则详情"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack" />
    <!-- 车行道 路内-->
    <van-row class="main"
             v-if="parkTypeCode===1">
      <van-row class="top">渝北停车场计费规则</van-row>
      <van-row class="tag">
        <van-tag color="#19A9FC"
                 type="middle"
                 round>按时长计费</van-tag>
      </van-row>
      <van-row class="divider"></van-row>
      <van-row class="middle textOne">计费规则细则</van-row>
      <van-row class="textThree"
               style="margin-top:12px;margin-bottom: 10px;">
        1.白天(8:00-20:00)</van-row>
      <van-row class="cell-title textFour">
        <van-col span="6">计费金额</van-col>
        <van-col span="6">免费时长</van-col>
        <van-col span="6">计费时长</van-col>
        <van-col span="6">封顶金额</van-col>
      </van-row>
      <van-row class="cell-value textTwo">
        <van-col span="6">1.00元</van-col>
        <van-col span="6">0分钟</van-col>
        <van-col span="6">30分钟</van-col>
        <van-col span="6">不封顶</van-col>
      </van-row>
      <van-row class="textThree"
               style="margin-top:12px;margin-bottom: 10px;">
        2.夜间(20:00-8:00)</van-row>
      <van-row class="cell-title textFour">
        <van-col span="6">计费金额</van-col>
        <van-col span="6">免费时长</van-col>
        <van-col span="6">计费时长</van-col>
        <van-col span="6">封顶金额</van-col>
      </van-row>
      <van-row class="cell-value textTwo">
        <van-col span="6">0元</van-col>
        <van-col span="6">0分钟</van-col>
        <van-col span="6">30分钟</van-col>
        <van-col span="6">不封顶</van-col>
      </van-row>
      <van-row class="middle textOne">计费规则描述</van-row>
      <van-row class="down textThree">
        一、重要商圈内路段占道停车收费标准及时段 <br>
        1.白天（8:00-22:00）时段每车每半小时2.5元；<br>
        2.夜间（22:00-次日8:00）时段每车每半小时2元，且每车每次最高不超过10元。<br>
        二、重要商圈外路段占道停车收费标准及时段<br>
        1.白天（8:00-20:00）时段每车每半小时1元；<br>
        2.夜间（20:00-次日8:00）时段不收费。<br>
        实施时间：<br>
        新标准自2014年9月1日起实施，之前占道停车收费标准同时作废。
      </van-row>
    </van-row>
    <!-- 人行道  路外-->
    <van-row class="main"
             v-if="parkTypeCode===2">
      <van-row class="top">渝北停车场计费规则</van-row>
      <van-row class="tag">
        <van-tag color="#19A9FC"
                 type="middle"
                 round>按时长计费</van-tag>
      </van-row>
      <van-row class="divider"></van-row>
      <van-row class="middle textOne">计费规则细则</van-row>
      <van-row class="textThree"
               style="margin-top:12px;margin-bottom: 10px;">
        1.白天(8:00-20:00)</van-row>
      <van-row class="cell-title textFour">
        <van-col span="6">计费金额</van-col>
        <van-col span="6">免费时长</van-col>
        <van-col span="6">计费时长</van-col>
        <van-col span="6">封顶金额</van-col>
      </van-row>
      <van-row class="cell-value textTwo">
        <van-col span="6">1.00元</van-col>
        <van-col span="6">0分钟</van-col>
        <van-col span="6">30分钟</van-col>
        <van-col span="6">不封顶</van-col>
      </van-row>
      <van-row class="textThree"
               style="margin-top:12px;margin-bottom: 10px;">
        2.夜间(20:00-8:00)</van-row>
      <van-row class="cell-title textFour">
        <van-col span="6">计费金额</van-col>
        <van-col span="6">免费时长</van-col>
        <van-col span="6">计费时长</van-col>
        <van-col span="6">封顶金额</van-col>
      </van-row>
      <van-row class="cell-value textTwo">
        <van-col span="6">1.00元</van-col>
        <van-col span="6">0分钟</van-col>
        <van-col span="6">30分钟</van-col>
        <van-col span="6">5元</van-col>
      </van-row>
      <van-row class="middle textOne">计费规则描述</van-row>
      <van-row class="down textThree">
        一、重要商圈内路段占道停车收费标准及时段 <br>
        1.白天（8:00-22:00）时段每车每半小时2.5元；<br>
        2.夜间（22:00-次日8:00）时段每车每半小时2元，且每车每次最高不超过10元。<br>
        二、重要商圈外路段占道停车收费标准及时段<br>
        1.白天（8:00-20:00）时段每车每半小时1元；<br>
        2.夜间（20:00-次日8:00）时段每车每半小时1元，且每车每次最高不超过5元。<br>
        实施时间：<br>
        新标准自2014年9月1日起实施，之前占道停车收费标准同时作废。
      </van-row>
    </van-row>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      parkTypeCode: 0
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.queryParkType()
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 查询停车场信息（类型）
    queryParkType () {
      const info = {
        parkId: this.$route.query.parkId
      }
      this.$queryDict.queryParkInfo(info).then(res => {
        this.parkTypeCode = res.resultEntity.parkTypeCode
      })
    },
    // 顶部返回按钮
    handleClickTopBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.billRuleDetail {
  // height: 100%;
  width: 100%;
  background-color: #fff;
  .main {
    margin: 12px 3.2% 0px 3.07%;
    height: 100%;
    width: 93.73%;
    .top {
      font-size: 17px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
    }
    .tag {
      margin: 8px 0 8px 0;

      // .van-tag {
      //   font-size: 11px;
      //   font-family: PingFang SC;
      //   font-weight: 400;
      //   color: #ffffff;
      //   border-radius: 8px;
      // }
    }
    .divider {
      width: 100%;
      border-top: 1px #cfcfcf dashed;
    }
    .middle {
      margin-top: 15px;
    }
    .cell-title {
      text-align: center;
      border-top: 1px #ebebeb solid;
      height: 30px;
      line-height: 30px;
    }
    .cell-value {
      text-align: center;
      border-top: 1px #ebebeb solid;
      border-bottom: 1px #ebebeb solid;
      height: 38px;
      line-height: 38px;
    }
    .down {
      margin-top: 10px;
      line-height: 23px;
    }
    .textOne {
      font-size: 17px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
    }
    .textTwo {
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
    }
    .textThree {
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #666666;
    }
    .textFour {
      font-size: 13px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #666666;
    }
  }
}
</style>
